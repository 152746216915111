import { HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Injectable, Injector } from '@angular/core';
import * as firebase from 'firebase/app';
import {
  Auth,
  User,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { environment } from 'src/environments/environment';
import { UserData } from '../models/user.model';
import { HealthMemberService } from '../services/health-member.service';
import { PatientService } from '../services/patient.service';
import { SnackbarCustomService } from '../services/snackbar-custom.service';
import {
  SNACK_MESSAGES,
  SNACK_TYPES,
} from '../shared/messages-constants';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public auth: Auth;
  public user: User;
  public userObserver: EventEmitter<any> = new EventEmitter(true);
  parsedToken: any;
  public userData: UserData = {};
  private healthMemberService: HealthMemberService;
  private patientService: PatientService;

  constructor(
    private injector: Injector,
    private snackBar: SnackbarCustomService,
    private cookiesService: CookiesService
  ) {
    this.auth = getAuth(
      firebase.initializeApp({
        apiKey: environment.firebaseConfig.apiKey,
        authDomain: environment.firebaseConfig.authDomain,
        projectId: environment.firebaseConfig.projectId,
        storageBucket: environment.firebaseConfig.storageBucket,
        messagingSenderId: environment.firebaseConfig.messagingSenderId,
        appId: environment.firebaseConfig.appId,
      })
    );
    this.auth.languageCode = 'pt-BR';
    this.auth.tenantId = environment.tenantId;
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.user = user;
        if (!this.isEmailVerified()) {
          this.sendEmailVerification();
        }
        this.userObserver.emit(this.user);
      } else {
        this.userObserver.emit(false);
      }
    });
  }

  signInWithEmailAndPassword = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  sendPasswordResetEmail = (email: string) => {
    return sendPasswordResetEmail(this.auth, email);
  };

  sendEmailVerification = (user = null) => {
    return sendEmailVerification(user ? user : this.user);
  };

  signOut() {
    this.cookiesService.clearAllCookies();
    signOut(this.auth);
    window.location.reload();
    return;
  };

  getUserData(): UserData {
    return this.userData;
  }

  getUserUid(): string {
    const userUid = this.cookiesService.getCookie('uid');
    return userUid ?? this.getUserData().uid;
  }

  getUserRole(): string {
    const userUid = this.cookiesService.getCookie('role');
    return userUid ?? this.userData.role;
  }

  async setUserData(data: UserData) {
    this.userData = data;
    if (!this.checkUserRole(ROLES.PATIENT)) {
      this.healthMemberService = this.injector.get(HealthMemberService);
      this.healthMemberService
        .getHealthMemberInfo()
        .then((value) => {
          this.userData.healthMember = value
          this.setLocalCookies(true)
        })
        .catch((error) => {
          if (error.status === HttpStatusCode.NotFound) {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.NOT_FOUND_HEALTH_MEMBER,
              SNACK_TYPES.INFO
            );
          } else {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.LOAD_HEALTH_MEMBER,
              SNACK_TYPES.ERROR
            );
          }
          this.signOut();
        });
    }
    if (this.checkUserRole(ROLES.PATIENT)) {
      this.patientService = this.injector.get(PatientService);
      this.patientService
        .getPatientInfo()
        .then((value) => {
          this.userData.patient = value
          this.setLocalCookies(false)
        })
        .catch((error) => {
          if (error.status !== HttpStatusCode.NotFound) {
            this.snackBar.openSnackBar(
              SNACK_MESSAGES.ERROR.LOAD_PATIENT,
              SNACK_TYPES.ERROR
            );
            this.signOut();
          }
        });
    }
  }

  async setLocalCookies(isMember: boolean) {
    const userToken = await this.getAPIToken();
    this.cookiesService.setCookie('role', this.userData.role, environment.cookiesExpiration);
    this.cookiesService.setCookie('uid', this.userData.uid, environment.cookiesExpiration);
    this.cookiesService.setCookie('token', userToken, environment.cookiesExpiration);
    if (isMember) {
      const institutionName = this.userData.healthMember?.nomeUnidadeSaude ?? '';
      this.cookiesService.setCookie('institutionName', institutionName, environment.cookiesExpiration);
      this.cookiesService.setCookie('userName', this.userData.healthMember.nome, environment.cookiesExpiration);
      return;
    }
    this.cookiesService.setCookie('isEmailVerified', String(this.auth.currentUser?.emailVerified), environment.cookiesExpiration);
    this.cookiesService.setCookie('userName', this.userData.patient.nome, environment.cookiesExpiration);
  }

  isEmailVerified(): boolean {
    const isVerified = this.cookiesService.getCookie('isEmailVerified');
    return isVerified == 'true' ? true : false;
  }

  checkUserRole(expectedRole: string): boolean {
    const userRole = this.getUserRole();
    return userRole === expectedRole;
  }

  async refreshUser(): Promise<void> {
    await this.auth.currentUser?.reload();
  }

  async buildAPIAuthHeader(contentType = null): Promise<HttpHeaders> {
    const cookieToken = this.cookiesService.getCookie('token');
    const token = cookieToken ?? await this.getAPIToken();
    if (contentType) {
      return new HttpHeaders().append('authorization', `Bearer ${token}`);
    }
    return new HttpHeaders()
      .append('authorization', `Bearer ${token}`)
      .append('Content-Type', 'application/json');
  }

  async getAPIToken(): Promise<string> {
    return await this.auth.currentUser?.getIdToken();
  }
}

export const ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  PATIENT: 'PATIENT',
  IGESTO_ADMIN: 'IGESTO_ADMIN',
};

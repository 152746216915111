import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import {
  Paciente,
  PatientCreatePayload,
  PatientFilterParams,
  PatientPatchPayload,
  PatientRegisterPayload,
} from 'src/app/models/paciente.model';
import { environment } from 'src/environments/environment';
import { TagList } from '../models/configuration.model';
import { ImportService } from '../shared/interfaces/import.interface';

@Injectable({
  providedIn: 'root',
})
export class PatientService implements ImportService {
  API = `${environment.backendUrl}paciente`;

  private haveDatePasswordSubject = new Subject<boolean>();
  haveDatePassword$: Observable<boolean> = this.haveDatePasswordSubject.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {}

  updateDatePassword(haveDatePassword: boolean) {
    this.haveDatePasswordSubject.next(haveDatePassword);
  }


  async import(file): Promise<Response> {
    try {
      const headers = await this.authService.buildAPIAuthHeader(file.type);
      const uploadedFile = new FormData();
      uploadedFile.append('file', file);
      return await this.http
        .post<Response>(this.API + '/importar', uploadedFile, { headers })
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  async loadPatientList(
    queryParams?: PatientFilterParams
  ): Promise<{ count: number; patients: Array<Paciente> }> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      const options = { headers, params: {} };

      if (queryParams) {
        let params = new HttpParams();

        if (queryParams.filterName != null && queryParams.filterName.length) {
          params = params.set('filterName', queryParams.filterName);
        }

        if (queryParams.filterTags != null && queryParams.filterTags.length) {
          params = params.set('filterEtiquetas', queryParams.filterTags);
        }

        if (queryParams.page) {
          params = params.set('page', queryParams.page);
        }

        if (queryParams.pageSize) {
          params = params.set('pageSize', queryParams.pageSize + 1);
        }

        if (queryParams.sortBy?.length) {
          params = params.set('sortBy', queryParams.sortBy);
        }

        if (queryParams.sortType?.length) {
          params = params.set('sortType', queryParams.sortType);
        }

        options.params = params;
      }

      return this.http
        .get<{ count: number; patients: Array<Paciente> }>(this.API, options)
        .toPromise();
    } catch (error) {
      throw Error('Get patient list failed!');
    }
  }

  async loadPatient(id: string): Promise<any> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http
        .get<Paciente>(`${this.API}/${id}`, { headers })
        .toPromise();
    } catch (error) {
      throw Error('Get patient failed!');
    }
  }

  async getPatientInfo(): Promise<Paciente> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http
        .get<Paciente>(this.API + `/${this.authService.getUserUid()}`, {
          headers,
        })
        .toPromise();
    } catch (error: any) {
      throw error.error.error;
    }
  }

  async patchPatient(id: number, payload: PatientPatchPayload): Promise<void> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      await this.http
        .patch(this.API + `/${id}`, payload, { headers })
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  async addRemoveTag(patientId: number, payload: TagList): Promise<void> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      await this.http
        .patch(this.API + `/${patientId}/etiquetas`, payload, { headers })
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  async registerPatient(
    payload: PatientCreatePayload | PatientRegisterPayload
  ): Promise<any> {
    try {
      const headers = await this.authService.buildAPIAuthHeader();
      return this.http.post(this.API, payload, { headers }).toPromise();
    } catch (error) {
      throw error;
    }
  }
}

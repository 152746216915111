import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PatientService } from './services/patient.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isFirstAccess: boolean = true;
  patientSubscription: Subscription;

  constructor(private patientService: PatientService) {}

  ngOnInit() {
    this.patientService.haveDatePassword$.subscribe((datePassword) => {
      this.isFirstAccess = datePassword;
    });
  }
}

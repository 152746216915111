import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject } from 'rxjs';
import { AuthService, ROLES } from 'src/app/auth/auth.service';
import { CookiesService } from 'src/app/auth/cookies.service';
import { SignService } from 'src/app/services/user-services/sign.service';
import { environment } from 'src/environments/environment';
import { navRouterSwitch } from '../util/export-functions/nav-router-switch';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoading = false;
  roles = ROLES;

  timedOut = false;
  lastPing?: Date = null;

  userInactive: Subject<any> = new Subject();

  constructor(
    public authService: AuthService,
    private signService: SignService,
    private router: Router,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private cookiesService: CookiesService
  ) { }

  ngOnInit() {
    this.startIdleTimerConfig();
  }

  startIdleTimerConfig() {
    this.idle.setIdle(environment.loginTimeout);
    this.idle.setTimeout(0);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.resetIdleTimer();
      this.cd.detectChanges();
    });

    this.idle.onIdleStart.subscribe(async () => {
      await this.signOut();
    });

    this.resetIdleTimer();
  }

  resetIdleTimer() {
    this.idle.watch();
    this.timedOut = false;
  }

  navbarRouter(path: string) {
    return this.router.navigate([navRouterSwitch(path)]);
  }

  isNavbarItemActive(itemPath: string): boolean {
    return this.router.url.includes(itemPath);
  }

  get isNotLoggedIn(): boolean {
    return !this.router.url.includes('app');
  }

  get isPatientFullRegistered(): boolean {
    return (
      this.authService.checkUserRole(this.roles.PATIENT) &&
      this.authService.isEmailVerified()
    );
  }

  async signOut(): Promise<void> {
    try {
      await this.signService.signOut();
      this.authService.signOut();
    } catch (e) {
      console.error(e);
    }
    await this.router.navigate(['signIn']);
  }

  get userName(): string {
    return this.cookiesService.getCookie('userName');
  }

  get institutionName(): string {
    return this.cookiesService.getCookie('institutionName');
  }

  get userRole() {
    return this.authService.getUserRole();
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, ROLES } from '../../auth/auth.service';
import { CookiesService } from '../../auth/cookies.service';

@Injectable({
    providedIn: 'root'
})
export class PatientGuardService implements CanActivate {

    constructor(
        public auth: AuthService,
        public router: Router,
        private cookiesService: CookiesService
    ) { }
    async canActivate(): Promise<boolean> {
        const role = this.cookiesService.getCookie('role');
        if (role != null) {
            if (role === ROLES.PATIENT) {
                return true;
            }
        }
        await this.router.navigate(['signIn']);
        return true;
    }

}

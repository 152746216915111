export const SNACK_TYPES = { SUCCESS: "Sucesso", ERROR: "Erro", INFO: "Info" };

export const SNACK_MESSAGES = {
    SUCCESS: {
        SIGN_UP: "Cadastro realizado com sucesso",
        LOGIN: "Acesso feito",
        RECOVER_PASSWORD: "Email para cadastrar uma nova senha foi enviado",
        CHANGE_PASSWORD: "Senha alterada com sucesso!",
        REGISTER_PATIENT: "Paciente cadastrado com sucesso",
        CREATE_VISIT: "Visita criada com sucesso",
        MEDICINE_PRESCRIPTION: "Prescrição salva com sucesso",
        UPDATE_VISIT: "Visita atualizada com sucesso",
        CREATE_INSTITUTION: "Instituição criada com sucesso",
        PATIENT_PATCH: "Paciente atualizado com sucesso",
        REGISTER_HEALTH_MEMBER: "Membro de saúde criado com sucesso",
        HEALTH_MEMBER_PATCH: "Membro de saúde atualizado com sucesso",
        HEALTH_INSTITUTION_PATCH: "Instituto de saúde atualizado com sucesso",
        FORM_ANSWERED_SUCCESSFULLY: "Questionário respondido com sucesso",
        CREATE_PATIENT_EVOLUTION: "Evolução do paciente criada com sucesso",
        PATCH_PATIENT_EVOLUTION: "Evolução do paciente criada com sucesso",
        CREATE_FORM: "Questionário criado com sucesso",
        DELETE_FORM: "Questionário deletado com sucesso",
        PATCH_FORM: "Questionário atualizado com sucesso",
        IMPORT: "A importação foi concluida com sucesso",
        TEMPLATE: "Template baixado com sucesso",
        CREATE_UNIT: "Unidade médica cadastrada com sucesso",
        UPDATE_UNIT: "Unidade médica atualizada com sucesso",
        DELETE_UNIT: "Unidade médica excluida com sucesso",
        CREATE_MEDICAL_VARIABLE: "Variável médica cadastrada com sucesso",
        UPDATE_MEDICAL_VARIABLE: "Variável médica atualizada com sucesso",
        DELETE_MEDICAL_VARIABLE: "Variável médica excluida com sucesso",
        CREATE_TAG: "Tag médica cadastrada com sucesso",
        UPDATE_TAG: "Tag médica atualizada com sucesso",
        DELETE_TAG: "Tag médica excluida com sucesso",
        CREATE_GROUP: "Grupo médico cadastrado com sucesso",
        UPDATE_GROUP: "Grupo médico atualizado com sucesso",
        DELETE_GROUP: "Grupo médico excluido com sucesso",
        CREATE_MEDICAL_TEMPLATE: "Template médico cadastrado com sucesso",
        PUBLISH_CARE_PLAN: "Plano de cuidados publicado com sucesso",
        UPDATE_CARE_PLAN: "Plano de cuidados atualizado com sucesso"
    },
    ERROR: {
        SIGN_UP: "O cadastro falhou.",
        LOGIN: "Usuário e/ou senha incorretos.",
        PERSIST_LOGIN: "Precisa de Ajuda? Clique em \"Esqueci a senha\"",
        USER_NOT_FOUND_EMAIL: "O usuário com este email não existe",
        USER_INVALID_EMAIL: "E-mail não é válido",
        USER_UNCHECKED_EMAIL: "E-mail não confirmado",
        RECOVER_PASSWORD: "O envio do email de recuperação de senha falhou",
        REGISTER_PATIENT: "O registro falhou",
        VERIFY_MAIL_FAILED: "Ocorreu um erro ao verificar o email, tente novamente mais tarde.",
        CHANGE_PASSWORD_FAILED: "Ocorreu um erro ao mudar a senha, tente novamente mais tarde.",
        LOAD_PATIENT: "Erro ao carregar paciente",
        LOAD_PATIENT_LIST: "Erro ao carregar paciente",
        FAIL_LOAD_FORM_LIST: "Erro ao carregar list de questionários",
        LOAD_HEALTH_MEMBER: "Erro ao carregar membro da unidade de saúde",
        FAIL_LOAD_MANAGEMENT_PATIENT: "Erro ao carregar informações do paciente",
        FAIL_LOAD_GROUP_TAGS_PATIENT: "Erro ao carregar as etiquetas do paciente",
        FAIL_SAVE_GROUP_TAGS_PATIENT: "Falha ao adicionar um grupo e etiqueta ao paciente",
        FAIL_LOAD_MANAGEMENT_PATIENT_TCLE: "Erro ao carregar os termos de aceite",
        NOT_FOUND_PATIENT: "O paciente não existe",
        NOT_FOUND_HEALTH_MEMBER: "O membro da unidade de saúde não existe ou não está listado",
        NOT_FOUND_REGISTER_ID: "Cadastro não encontrado",
        BAD_PAYLOAD_CREATE_VISIT: "Os dados inseridos para criar a visita não são válidos",
        BAD_PAYLOAD_UPDATE_VISIT: "Erro ao atualizar a visita",
        INTERNAL_ERROR: "Ocorreu um erro ao carregar todas as informações, por favor tente novamente mais tarde",
        INTERNAL_FORM_ERROR: "Ocorreu um erro ao carregar os questionários, por favor tente novamente mais tarde",
        GET_HEALTH_MEMBER_LIST: "Um erro ocorreu ao carregar a lista de membro de equipe de saúde",
        GET_FORM_LIST: "Um erro ocorreu ao carregar a lista de questionários",
        GET_MEDICINE_PRESCRIPTION_LIST: "Um erro ocorreu ao carregar a lista de prescrições médicas",
        GET_CARE_PLAN_LIST: "Um erro ocorreu ao carregar a lista de planos de cuidados médicos",
        INSTITUTION_ALREADY_EXIST: "A unidade de saúde já existe",
        CREATE_INSTITUTION_FAIL: "Ocorreu um problema no registro da unidade de saúde",
        UPDATE_INSTITUTION_FAIL: "Ocorreu um problema atualizando a unidade de saúde",
        FIND_INSTITUTION_FAIL: "Ocorreu um problema ao resgatar informações da unidade de saúde",
        LOAD_EVOLUTION_FAIL: "Ocorreu um problema ao carregar as informações de evolução do paciente",
        PATCH_EVOLUTION_FAIL: "Ocorreu um problema ao salvar a evolução do paciente",
        HEALTH_MEMBER_RG_ALREADY_EXIST: "Um membro com este RG já existe",
        HEALTH_MEMBER_CPF_ALREADY_EXIST: "Um membro com este CPF já existe",
        HEALTH_MEMBER_ALREADY_EXIST: "Um membro/admin com este email, cpf ou rg já existe",
        CREATE_HEALTH_MEMBER_FAILED: "Ocorreu um problema no cadastro do membro da equipe de saúde",
        UPDATE_HEALTH_MEMBER_FAILED: "Ocorreu um problema atualizando o membro da equipe de saúde",
        NOT_FOUND_VISIT: "Visita não encontrada",
        NOT_FOUND_CARE_PLAN: "Plano de cuidados não encontrado",
        PATIENT_ALREADY_REGISTERED: "O paciente já está cadastrado",
        FAIL_TO_ANSWER_FORM: "Ocorreu um problema ao responder o questionário",
        ERROR_MEDICINE_PRESCRIPTION: "Falha ao salvar a prescrição médica",
        UNAUTHORIZED_LOAD_FORM: "Você não pode acessar este questionário",
        FAIL_TO_LOAD_FORM: "Não foi possível carregar este questionário",
        FORM_ALREADY_ANSWER: "Este questionário já foi respondido",
        FAIL_LOAD_ALL_PATIENT: "Erro ao carregar todas as informações do usuário",
        FAIL_TO_CREATE_FORM: "Não foi possível criar questionário",
        FAIL_TO_DELETE_FORM: "Não foi possível deletar questionário",
        FAIL_TO_PATCH_FORM: "Não foi possível atualizar questionário",
        ERROR_LOAD_DOSE_UNIT_LIST_PRESCRIPTION: "Erro ao carregar lista de unidades de dosagem",
        ERROR_LOAD_MEDICINE_PRESCRIPTION: "Erro ao carregar prescrição médica",
        ERROR_CREATE_MEDICINE_PRESCRIPTION: "Erro ao criar prescrição médica",
        ERROR_UPDATE_MEDICINE_PRESCRIPTION: "Erro ao atualizar prescrição médica",
        FAIL_IMPORT: "A importação falhou",
        ERROR_FILE_TYPE: "Apenas arquivos .csv são aceitos",
        FAIL_ITEMS_LOAD_OF_TEMPLATE: "Houve uma falha em carregar os itens",
        FAIL_TO_LOAD_TEMPLATE_TO_IMPORT: "Houve uma falha em carregar o template para a importação",
        CREATE_MEDICAL_UNIT_FAILED: "Cadastro da unidade médica falhou",
        UPDATE_MEDICAL_UNIT_FAILED: "Falha em atualizar unidade médica",
        ACTIVATE_MEDICAL_UNIT_FAILED: "Falha ao ativar ou desativar unidade médica",
        DELETE_MEDICAL_UNIT_FAILED: "Falha em excluir unidade médica",
        FETCH_MEDICAL_UNIT_FAILED: "Carregamento da unidade médica falhou",
        CREATE_MEDICAL_GREATNESS_FAILED: "Cadastro da variável médica falhou",
        UPDATE_MEDICAL_GREATNESS_FAILED: "Falha em atualizar variável médica",
        ACTIVATE_MEDICAL_GREATNESS_FAILED: "Falha ao ativar ou desativar variável médica",
        ACTIVATE_MEDICAL_PLAN_FAILED: "Falha ao ativar ou desativar plano médico",
        CREATE_MEDICAL_TAG_FAILED: "Cadastro da etiqueta médica falhou",
        UPDATE_MEDICAL_TAG_FAILED: "Falha em atualizar etiqueta médica",
        UPDATE_MEDICAL_CARE_PLAN_FAILED: "Falha em atualizar plano de cuidados",
        CREATE_MEDICAL_CARE_PLAN_FAILED: "Falha em criar novo plano de cuidados",
        ACTIVATE_MEDICAL_TAG_FAILED: "Falha ao ativar ou desativar etiqueta médica",
        DELETE_MEDICAL_TAG_FAILED: "Falha em excluir etiqueta médica",
        FETCH_MEDICAL_TAG_FAILED: "Carregamento da etiqueta médica falhou",
        CREATE_MEDICAL_GROUP_FAILED: "Cadastro do grupo médico falhou",
        UPDATE_MEDICAL_GROUP_FAILED: "Falha em atualizar grupo médico",
        ACTIVATE_MEDICAL_GROUP_FAILED: "Falha ao ativar ou desativar grupo médico",
        DELETE_MEDICAL_GROUP_FAILED: "Falha em excluir grupo médico",
        FETCH_MEDICAL_GROUP_FAILED: "Carregamento do grupo médico falhou",
        CREATE_MEDICAL_TEMPLATE_FAILED: "Cadastro do template médico falhou",
        UPDATE_MEDICAL_TEMPLATE_FAILED: "Falha ao ativar ou desativar template médico",
        PUBLISH_CARE_PLAN_FAILED: "Falha ao publicar plano de cuidado",
    },
    INFO: {
        EMPTY_RESULT: "Nenhum resultado encontrado na pesquisa",
        EMPTY_ANSWER_LIST: "Adicione ao menos uma resposta a perguntas com opções",
        MEDICAL_GREATNESS_ALREADY_CREATED: "Nome de grandeza médica já cadastrado",
        MEDICAL_TAG_ALREADY_CREATED: "Nome da etiqueta médica já cadastrada",
        TEMPORARY_PASSWORD_SENT: "Paciente cadastrado com sucesso! Senha temporária enviada ao paciente.",
        TCLE_NOT_ACCEPTED: "Para cadastrar o paciente, é necessário aceitar o termo."
    },
}

export const SNACK_DEFAULT_DURATION = 6 * 1000;

export const SNACK_DEFAULT_VERTICAL_POSITION = "top";

export const SNACK_DEFAULT_HORIZONTAL_POSITION = "right";